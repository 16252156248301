<template>
  <div>
    <div v-show="!isNull">
      <md-scroll-view class="scroll"
                      ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMore">
        <div class="scroll">
          <div class="poster"
               v-for="(item,index) in imgLists"
               :key="index">
            <img class="img"
                 @click="use(item.osno)"
                 :src="ossurl+item.pichttp" />
            <div class="btn"
                 :style="`backgroundColor:${colorprimary}`"
                 @click="delPoster(item.sno)">删除</div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="loading" />
      </md-scroll-view>
    </div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
    <md-landscape v-model="showPic">
      <img :src="sctp">
    </md-landscape>
  </div>
</template>
<script>
import { selectPage, deletesc, useHB } from '@/api/abt/customerOperation/common'
import loadMore from '@/mixins/loadmore'
import { getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
export default {
  mixins: [loadMore],
  data () {
    return {
      ossurl: '',
      height: '',
      pageNo: 1,
      size: 12,
      total: 0,
      totalPage: 0,
      loading: false,
      imgLists: [],
      isNull: true,
      sctp: '',
      showPic: false,
      user: '',
      colorprimary: ''
    }
  },
  created () {
    this.height = document.body.clientHeight
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.getData()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height}px`
  },
  methods: {
    async getData (isInit = true) {
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size,
        flag: '1',
        btagcode: '2',
        // stagcode: this.stagcode
        stagcode: ''
      }
      let res = await selectPage(data)
      this.imgLists = isInit ? res.data.data.rows : this.imgLists.concat(res.data.data.rows)
      if (this.imgLists.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    },
    use (val) {
      Toast.loading('生成中...')
      this.sctp = ''
      useHB({ sno: val, flag: '2', rytype: this.user.rytype }).then(res => {
        this.sctp = this.ossurl + res.data.data.url
        this.showPic = true
        Toast.hide()
      })
    },
    delPoster (val) {
      deletesc({ sno: val }).then(res => {
        Toast.succeed('删除成功')
        this.imgLists = []
        this.pageNo = 1
        this.getData()
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.scroll {
  overflow-y: auto;
}

.poster {
  float: left;
  width: 33%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  .img {
    width: 100%;
    height: 500px;
  }

  .btn {
    position: absolute;
    right: 20px;
    bottom: 50px;
    width: 120px;
    line-height: 60px;
    text-align: right;
    padding: 3px 10px 3px 8px;
    //background-color: color-primary;
    color: white;
    font-weight: bold;
    font-size: 35px;
    letter-spacing: 5px;
    -webkit-border-bottom-left-radius: 1rem;
    -webkit-border-top-left-radius: 1rem;
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

/deep/.md-landscape-content {
  width: 740px;
}
</style>
